import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/Blocks/SEO'
import NoAccount from '../components/Pages/NoAccount'

function NoAccountPage({
  data: {
    allCasinoJson: { nodes },
  },
  ...props
}) {
  return (
    <React.Fragment>
      <SEO
        title="Casino utan registrering (2020)."
        description="Letar du efter ett svenskt casino utan registrering och konto? Spana in den här exklusiva listan med alla casinon som inte kräver krånglig registrering 2020."
      />
      <NoAccount casinos={nodes} />
    </React.Fragment>
  )
}

export default NoAccountPage

export const query = graphql`
  query Casinos {
    allCasinoJson(filter: { account: { eq: false } }) {
      nodes {
        name
        license
        account
        url
        logo {
          src
          bg
        }
      }
    }
  }
`

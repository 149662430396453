import React from 'react'
import styles from './ListItem.module.css'

function ListItem({ item, ...props }) {
  return (
    <li className={styles.listItem}>
      <div className={styles.itemHeader}>
        <h2 className={styles.itemHeading}>{item.name}</h2>
        {item && item.logo && item.logo.hasOwnProperty('src') ? (
          <div
            className={styles.logo__bg}
            style={
              item.logo.bg !== null
                ? {
                    background: item.logo.bg,
                  }
                : {}
            }
          >
            <img
              className={styles.itemLogo}
              src={item.logo.src}
              alt={`${item.name} logotyp`}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.itemContent}>
        <a className={styles.itemButton} href={item.url} rel="nofollow">
          Till {item.name}
        </a>
        <p className={styles.disclaimer}>
          18+ Spela ansvarsfullt, regler & villkor gäller –
          <a
            className={styles.link}
            rel="nofollow"
            href="https://www.stodlinjen.se"
          >
            Stödlinjen.se
          </a>
        </p>
      </div>
    </li>
  )
}

export default ListItem

import React from 'react'
import styles from './NoAccount.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'
import List from '../Elements/List'
import ListItem from '../Elements/ListItem'

function NoAccount({ casinos, ...props }) {
  return (
    <React.Fragment>
      <Header>
        <Hero heading="Vill du spela på ett svenskt casino utan registrering?">
          <p className={styles.preamble}>
            — Förlåt, det var en dum fråga. Det är klart att du vill spela på
            ett <strong>casino utan krånglig registrering</strong> där du
            slipper mecka med att fylla i de långa formulär som ofta krävs för
            att skapa ett konto och komma igång med det du egentligen är där för
            att göra: <strong>spela på casino.</strong> Den riktiga fråga borde
            väl egentligen vara, vilket <strong>svenskt casino</strong> utan
            registrering vill du spela på?
          </p>
          <p>
            Eftersom att flera svenska casinon 2020 har insett att du som
            spelare vill komma igång och spela snabbt utan jidder har de börjat
            erbjuda sina spelare spel utan registrering. Vad det innebär och
            vilka fördelar det har ska vi avslöja för dig på den här sidan. Det
            finns idag en hel uppsjö av casinon med svensk licens på nätet, hur
            ska du veta vilket du ska välja om du vill slippa gå igenom en
            krånglig registrering?
          </p>
        </Hero>
      </Header>
      <Page>
        <Content>
          <section>
            <h2>
              Lista på svenska casino utan krav på konto och krånglig
              registrering.
            </h2>
            <List>
              {casinos &&
                casinos.length > 0 &&
                casinos.map((casino, index) => (
                  <ListItem item={casino} key={index} />
                ))}
            </List>
            <p>
              Vi vet hur segt det är att leta efter riktigt bra svenska casinon
              och har med anledning av det skapat en lista på den här sidan där
              vi har samlat alla svenska casinon utan krav på krånglig
              registrering. Listan innehåller bara casinon med svensk spellicens
              så att du, trots enkelheten, ska kunna känna dig trygg, kunna göra
              enkla, snabba uttag och som en extra bonus, slippa betala skatt på
              dina vinster.
            </p>
          </section>
          <section>
            <h2>
              Fördelar med att spela på ett svenskt casino utan registrering
            </h2>
            <ul>
              <li>Inget konto eller krånglig registrering.</li>
              <li>
                Du behöver inte komma ihåg något användarnamn och lösenord.
              </li>
              <li>
                Inga krav på att du ska skicka in dokument och verifieringar.
              </li>
              <li>Du verifierar dig istället säkert med BankID.</li>
              <li>
                Inget spam på e-post eller sms eftersom att du inte har angivit
                några kontaktuppgifter.
              </li>
              <li>Kostnadsfria insättningar och uttag.</li>
              <li>Snabba utbetalningar.</li>
              <li>Skattefria vinster.</li>
            </ul>
          </section>
          <section>
            <h2>Svar på vanliga frågor om spel utan konto</h2>
            <dl className={styles.qna}>
              <dt className={styles.question}>
                Vad menas med casino utan konto och registrering?
              </dt>
              <dd className={styles.answer}>
                Ett casino utan konto och registrering är helt enkelt ett
                onlinecasino som inte kräver att du, genom en krånglig process,
                behöver fylla i ett långt formulär med massa personuppgifter
                bara för att få komma igång och spela. Istället verifierar du
                dig snabbt och enkelt med BankID.
              </dd>
              <dt className={styles.question}>
                Hur fungerar spel på casino utan konto?
              </dt>
              <dd className={styles.answer}>
                Att spela på ett casino utan konto skiljer sig i regel inte från
                att spela på ett casino med ett traditionellt konto. Allt du
                behöver göra är att hitta ett spännande spel, sätta in pengar på
                casinot med hjälp av, Swish, Trustly, BankID eller bankdosa
                eller något av de alternativ som erbjuds.
              </dd>
              <dt className={styles.question}>
                Finns det några svenska casinon utan registrering?
              </dt>
              <dd className={styles.answer}>
                Ja, på den här sidan har vi enbart skapat en lista med de
                svenska casinon som erbjuder spel utan registrering och konto.
              </dd>
              <dt className={styles.question}>
                Hur gör man en insättning på ett casino utan att registrera sig?
              </dt>
              <dd className={styles.answer}>
                När du besöker ett casino som erbjuder spel utan konto trycker
                du i regel på en knapp med texten “sätt in pengar”, “spela nu”
                eller något liknande. Du får då välja hur mycket pengar du vill
                sätta in hos casinot (tänk på att du inte måste spela för allt
                vid samma tillfälle). När du har valt ett belopp du vill sätta
                in får du välja om du vill överföra pengar med Swish eller via
                din bank med hjälp av Trustly. Väljer du att göra insättningen
                med Swish är det sen bara att ange ditt personnummer för att
                kunna identifiera dig med BankID. Om du istället väljer att göra
                insättningen med ett bankkonto, välj då den bank där du har ditt
                konto följt av om vill identifiera dig med BankID eller med
                bankdosa. När du har identifierat dig kan du se att ditt saldo
                nu är uppdaterat med det belopp du valde att sätta in. Du är nu
                redo att börja spela på casinot.
              </dd>
              <dt className={styles.question}>
                Vad är Trustly och behöver man ett konto hos Trustly för att
                kunna göra en insättning?
              </dt>
              <dd className={styles.answer}>
                Trustly är ett svenskt bolag som gör att du snabbt och säkert
                kan göra en insättning direkt från ditt bankkonto. De flesta
                casinosidor har Trustly integrerat i sina betalsystem vilket gör
                att du inte behöver ha ett konto för att kunna göra en snabb
                insättning. Du väljer bara vilken bank du har och identifierar
                dig sedan med BankID.
              </dd>
              <dt className={styles.question}>
                Kostar det något att sätta in och ta ut pengar?
              </dt>
              <dd className={styles.answer}>
                Nej, det är givetvis kostnadsfritt att sätta in och ta ut pengar
                till och från casinot.
              </dd>
              <dt className={styles.question}>
                Vad händer om jag stänger ner sidan trots att det finns pengar
                kvar att spela för?
              </dt>
              <dd className={styles.answer}>
                Ingenting – Om du vill ta en paus är det bara att logga ut eller
                stänga ner sidan. Pengarna du har satt in kommer finnas kvar när
                du kommer tillbaka eftersom att du har verifierat dig med
                BankID.
              </dd>
              <dt className={styles.question}>
                Hur gör man ett uttag från ett casino utan registrering?
              </dt>
              <dd className={styles.answer}>
                Skulle du vilja göra ett uttag av dina spelvinster klickar du på
                knappen för uttag. Du anger sedan hur mycket pengar du vill ta
                ut.
              </dd>
              <dt className={styles.question}>
                Om jag gör ett uttag från ett casino, hur snabbt får jag
                pengarna?
              </dt>
              <dd className={styles.answer}>
                Snabbt som blixten. Gör du ett uttag när du har spelat på ett
                casino utan konto har du i regel pengarna på ditt konto inom
                några minuter.
              </dd>
            </dl>
          </section>
          <section>
            <h2>Casino med BankID</h2>
            <p>
              För att du ska kunna spela utan konto på något av de svenska
              casinon på vår lista som erbjuder spel utan krånglig registrering
              krävs det att du har ett BankID. BankID som är en elektronisk
              id-handling, gör att det är möjligt för dig som spelare att på ett
              snabbt och säkert sätt kunna identifiera dig hos casinot.{' '}
            </p>
          </section>
        </Content>
      </Page>
    </React.Fragment>
  )
}

export default NoAccount
